$primaryColor: var(--primary-color);
$primaryTextColor: #ffffff;
$accentColor: #7cb342;
$accentTextColor: #ffffff;

@use 'sass:string';
@use 'sass:color';
@use 'sass:math';

@function getUnitlessValue($value) {
	@if type-of($value) == 'number' and not unitless($value) {
		@return math.div($value, $value * 0 + 1);
	}

	@return $value;
}

@function getCssVariableName($value) {
	@if type-of($value) == 'string' and str-index($value, 'var(--') {
		$index: str-index($value, 'var(--');
		$variableName: string.slice($value, $index + 6);
		@return string.slice($variableName, 1, str-index($variableName, ')') - 1);
	}

	@return null;
}

@function darken($color, $value) {
	$variableName: getCssVariableName($color);
	@if $variableName != null {
		@return #{'var(--#{$variableName}-D#{getUnitlessValue($value)})'};
	}

	@return adjust-color($color, $lightness: -$value);
}

@function scale-color($color, $lightness) {
	$variableName: getCssVariableName($color);
	@if $variableName != null {
		@return #{'var(--#{$variableName}-S#{getUnitlessValue($lightness)})'};
	}

	@return color.scale($color, $lightness: $lightness);
}

@function rgba($r, $g, $b: null, $a: null) {
	@if $b == null and $a == null {
		$variableName: getCssVariableName($r);
		@if $variableName != null {
			@return #{'rgba(var(--#{$variableName}-rgb), #{$g})'};
		} @else {
			@return #{'rgba(#{$r}, #{$g})'};
		}
	}

	@return #{'rgba(#{$r}, #{$g}, #{$b}, #{$a})'};
}

@import '../sass/theme/theme_dark';
