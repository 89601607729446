.p-dialog-mask {
	.p-dialog {
		&-header {
			.p-dialog-title {
				letter-spacing: 0.04rem;
			}
		}

		&-content {
			line-height: 1.5rem;

			.confirm-dialog-title {
				margin-bottom: 1.25rem;
				display: flex;
				align-items: center;
				column-gap: 1rem;

				.p-confirm-dialog-icon {
					font-size: 20px;

					&.error-icon {
						color: $error-primary-color;
					}
				}

				.p-confirm-dialog-message {
					line-height: 1.5rem;
					letter-spacing: 0.035rem;
				}
			}

			.copy-link {
				&-container {
					padding: 1rem;
					column-gap: 0.5rem;
					color: lightgrey;
				}

				&-icon {
					font-size: 16px;
				}

				&-label {
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		&-footer {
			&.app-dialog-footer {
				padding: 0;
			}

			p-button,
			p-button,
			button {
				&:last-child {
					button {
						margin-right: 0;
					}
				}
			}
		}
	}
}
