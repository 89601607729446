/* raleway-regular - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 400;
	src: local(''), url('../../fonts/raleway-v27-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'),
		('../../fonts/raleway-v27-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff');
}

@font-face {
	font-family: 'lakefont';
	src: url('../../fonts/lakefont.eot?23607874');
	src: url('../../fonts/lakefont.eot?23607874#iefix') format('embedded-opentype'), url('../../fonts/lakefont.woff?23607874') format('woff'),
		url('../../fonts/lakefont.ttf?23607874') format('truetype'), url('../../fonts/lakefont.svg?23607874#lakefont') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^='icon-']:before,
[class*=' icon-']:before {
	font-family: 'lakefont';
	font-style: normal;
	font-weight: normal;

	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: 0.2em;
	text-align: center;
	/* opacity: .8; */

	/* For safety - reset parent styles, that can break glyph codes*/
	font-variant: normal;
	text-transform: none;

	/* fix buttons height, for twitter bootstrap */
	line-height: 1em;

	/* Animation center compensation - margins should be symmetric */
	/* remove if not needed */
	margin-left: 0.2em;

	/* you can be more comfortable with increased icons size */
	/* font-size: 120%; */

	/* Font smoothing. That was taken from TWBS */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	/* Uncomment for 3D effect */
	/* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-sor:before {
	content: '\e800';
}

/* '' */
.icon-wo:before {
	content: '\f1c2';
}

/* '' */
.icon-po:before {
	content: '\f1c4';
}

/* '' */

.lake-icon {
	font-family: 'lakefont';
	font-style: normal;
	font-weight: normal;

	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: 0.2em;
	text-align: center;
	/* opacity: .8; */

	/* For safety - reset parent styles, that can break glyph codes*/
	font-variant: normal;
	text-transform: none;

	/* fix buttons height, for twitter bootstrap */
	line-height: 1em;

	/* Animation center compensation - margins should be symmetric */
	/* remove if not needed */
	margin-left: 0.2em;

	/* You can be more comfortable with increased icons size */
	/* font-size: 120%; */

	/* Font smoothing. That was taken from TWBS */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	/* Uncomment for 3D effect */
	/* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

body {
	background-color: var(--surface-card);
}

.layout-wrapper .layout-main {
	.layout-topbar {
		background: $topbarBgColor;
	}

	.layout-footer {
		border: none;
		background: var(--surface-card);
	}
}

.layout-content {
	padding: 0 !important;
	background: var(--surface-card);
}

.card {
	@include no-shadow();
}

.title-label-small {
	color: rgba(255, 255, 255, 0.87);
	font-size: 9px;
	width: 100%;
}

.field-label-small {
	color: rgba(255, 255, 255, 0.5);
	font-size: 9px;
}

.error-message {
	color: #b00020;
	font-size: 0.9em;
}

.field-read-only {
	padding-top: 0.5rem;
	padding-right: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 0.5rem;
	border-bottom: 1px solid #ffffff;
	border-left: 1px solid #ffffff;
	border-right: 1px solid #ffffff;
	min-height: 32px;
}

.field-label-read-only {
	color: rgba(255, 255, 255, 0.5);
}

.field.p-float-label.p-fluid input:disabled {
	border: 1px solid #ffffff;
	opacity: 1 !important;
}

.p-fieldset {
	border: 0;
}

.div-header {
	font-weight: bold;
}

.form-element {
	min-height: 55px;
	padding-right: 1.2em !important;
	padding-left: 1.2em !important;
}

.form-element-area {
	min-height: 125px;
	padding-right: 1.2em !important;
	padding-left: 1.2em !important;
}

.table-header-label {
	white-space: nowrap;
}

.p-tooltip {
	.p-tooltip-text.p-tooltip-text {
		width: fit-content;
		word-break: break-word;
	}
}

.footer-right {
	position: fixed;
	bottom: 5px;
	right: 5px;
}

.footer-left {
	float: left;
	position: absolute;
	bottom: 0;
}

.img-no-highlight {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.p-toast.p-toast-custom {
	top: 150px;
}

.custom-pagination {
	box-sizing: border-box;
	padding-bottom: 16px;
	padding-top: 16px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	border-bottom: 1px solid rgb(64, 64, 64);
	color: rgba(255, 255, 255, 0.6);
}

.sub-heading {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 19.6px;
	line-height: 23px;
	padding-left: 0.8em !important;
}

.layout-wrapper {
	&.table-overflow-page {
		display: flex;
		height: 100vh;
		flex-grow: 1;
		overflow: visible;
	}
}

* {
	@-moz-document url-prefix() {
		scrollbar-width: thin; /* For Firefox */
		scrollbar-color: #949494 #eee; /* For Firefox */
	}

	&::-webkit-scrollbar {
		width: 8px;
		height: 12px;
	}

	&::-webkit-scrollbar-track {
		border-radius: 8px;
		background-color: #eee;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 8px;
		border: 3px solid transparent;
		background-clip: content-box;
		background-color: #949494;

		&:hover {
			background-color: #636363;
		}
	}
}

/* Override PrimeNG listbox max-height to allow full content display */
/* This is a workaround for the issue where the listbox content is not fully displayed */
.p-listbox-list-wrapper {
	max-height: unset !important;
}

/* dashboard */
p-chart {
    canvas {
        height: 210px !important;
    }
}

.p-paginator {
	justify-content: center;
}
