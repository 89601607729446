$error-primary-color: #ff0000;
$primary-colors: (
	default: #607d8b,
	scope: #04a8a6,
	quote: #d0da59,
	workorder: #be0aff,
	property: #69e182,
	invoice: #909e3d,
	sor: #c66630,
	purchaseorder: #0c7fe8,
	recovery: #9ec7ed,
	credit: #61a3df,
	service-request: #22526b,
	contact: #ed6565,
	payables: #f51a88,
	receivables: #f862ae,
	calendar: #bbc9a1,
	asset: #9683a3,
	defect: #f19436,
	schedule: #baed37,
);

$default-primary-color: map-get($primary-colors, default);
$scope-primary-color: map-get($primary-colors, scope);
$quote-primary-color: map-get($primary-colors, quote);
$workorder-primary-color: map-get($primary-colors, workorder);
$property-primary-color: map-get($primary-colors, property);
$invoice-primary-color: map-get($primary-colors, invoice);
$sor-primary-color: map-get($primary-colors, sor);
$purchaseorder-primary-color: map-get($primary-colors, purchaseorder);
$service-request-primary-color: map-get($primary-colors, service-request);
$contact-primary-color: map-get($primary-colors, contact);
$payables-primary-color: map-get($primary-colors, payables);
$receivables-primary-color: map-get($primary-colors, receivables);
$calendar-primary-color: map-get($primary-colors, calendar);
$asset-primary-color: map-get($primary-colors, asset);
$defect-primary-color: map-get($primary-colors, defect);
$recovery-primary-color: map-get($primary-colors, recovery);
$credit-primary-color: map-get($primary-colors, credit);
$schedule-primary-color: map-get($primary-colors, schedule);

$error-primary-color: #ff0000;
$success-primary-color: #00ff35;
$transparent-color: transparent;
