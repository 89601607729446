$actionIconWidth: 2rem;
$actionIconHeight: 2rem;

$inputPadding: 0.75rem 0.75rem;
$inputListItemPadding: 0.75rem 0.75rem;
$inputListHeaderPadding: 0.75rem;
$inputGroupAddOnMinWidth: 2.75rem;

$multiselectHeaderIconWidth: 2.25rem;
$multiselectHeaderIconHeight: 0.2.25rem;

$buttonPadding: 0.643rem 0.75rem;
$buttonIconOnlyWidth: 2.75rem;
$buttonIconOnlyPadding: 0.643rem;

$ratingIconFontSize: 1rem;

$calendarCellDateWidth: 2.25rem;
$calendarCellDateHeight: 2.25rem;

$calendarActionIconWidth: 2.25rem;
$calendarActionIconHeight: 2.25rem;

$calendarButtonBarPadding: 0.75rem 0;

$panelHeaderPadding: 0.75rem;
$panelContentPadding: 0.75rem;
$panelFooterPadding: 0.75rem 0.75rem;

$accordionHeaderPadding: 1.25rem;
$accordionContentPadding: 0.75rem 1.25rem;

$tabviewHeaderPadding: 0.75rem 1.25rem;
$tabviewContentPadding: 0.75rem;

$cardBodyPadding: 0.75rem;
$cardContentPadding: 0.75rem 0;
$cardFooterPadding: 0.75rem 0 0 0;
$paginatorPadding: 0.375rem 0.75rem;

$tableHeaderPadding: 0.75rem 0.75rem;
$tableHeaderCellPadding: 0.75rem 0.75rem;
$tableBodyCellPadding: 0.75rem 0.75rem;
$tableFooterCellPadding: 0.75rem 0.75rem;
$tableFooterPadding: 0.75rem 0.75rem;

$dataViewContentPadding: 0.75rem;
$treeNodeContentPadding: 0.25rem;

$messageMargin: 0.75rem 0;
$messagePadding: 1rem 1.25rem;
$messageIconFontSize: 1.5rem;
$messageCloseIconWidth: 2.25rem;
$messageCloseIconHeight: 2.25rem;
$inlineMessagePadding: 0.75rem 0.75rem;

$toastPadding: 1.25rem;

$toastCloseIconWidth: 1.5rem;
$toastCloseIconHeight: 1.5rem;

$dialogHeaderPadding: 1.25rem;
$dialogContentPadding: 0 1.25rem 1.25rem 1.25rem;
$dialogFooterPadding: 0.75rem 1.25rem;

$dialogHeaderActionIconWidth: 2.25rem;
$dialogHeaderActionIconHeight: 2.25rem;

$sidebarCloseIconWidth: 2.25rem;
$sidebarCloseIconHeight: 2.25rem;

$overlayPanelCloseIconWidth: 2.25rem;
$overlayPanelCloseIconHeight: 2.25rem;

$menuitemPadding: 0.75rem 0.75rem;
$submenuHeaderPadding: 0.75rem;

$breadcrumbPadding: 0.75rem;

$horizontalMenuPadding: 0.75rem;

$horizontalMenuRootMenuitemPadding: 0.75rem;

$menubarButtonWidth: 2.25rem;
$menubarButtonHeight: 2.25rem;

$carouselNavIconWidth: 2.25rem;
$carouselNavIconHeight: 2.25rem;
