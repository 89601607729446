@function toRGB($hex) {
	@return red($hex), green($hex), blue($hex);
}

@function toHSL($hex) {
	@return hue($hex), saturation($hex), lightness($hex);
}

@mixin app-primary-color($value) {
	--primary-color: #{$value};
	--primary-color-rgb: #{toRGB($value)};
	--primary-color-hsl: #{toHSL($value)};
	--primary-color-h: #{hue($value)};
	--primary-color-s: #{saturation($value)};
	--primary-color-l: #{lightness($value)};

	// Values for primeng sass color function override
	--primary-color-S-20: hsl(var(--primary-color-h), var(--primary-color-s), calc(var(--primary-color-l) * 0.8));
	--primary-color-D25: hsl(var(--primary-color-h), var(--primary-color-s), calc(var(--primary-color-l) - 25%));
}
