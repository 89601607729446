.formatted-date-holder {
	display: flex;
	flex-wrap: wrap;
	column-gap: 4px;
}

.button-with-tooltip-holder {
	display: inline-block;
	margin: 4px !important;
}

.select-button-toolbar {
	margin: 3px 4px 3px 4px !important;
}
