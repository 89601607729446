.p-buttonset {
	.p-button {
		border-radius: 0;

		&:first-child {
			border-radius: 4px 0 0 4px;
		}

		&:last-child {
			border-radius: 0 4px 4px 0;
		}
	}
}
