/* Add your customizations of theme here */

.p-button {
	font-weight: 700;
	text-transform: uppercase;
	&:disabled {
		pointer-events: auto !important;
	}
}

.p-tabview .p-tabview-nav li:not(.p-disabled):hover {
	.p-tabview-nav-link {
		background: $tabviewHeaderHoverBg;
	}
}

.p-buttonset .p-button {
	justify-content: center;
}

.p-datatable {
	.p-selectbutton {
		.p-button {
			background: $datatableSelectButtonBg;
		}
	}
}

.main-quote {
	p-button {
		.p-button {
			.p-button-icon {
				color: black !important;
			}

			.p-button-icon:hover {
				color: black !important;
			}

			.p-button-label {
				color: black !important;
			}

			.p-button-label:hover {
				color: black !important;
			}
		}
	}

	p-selectbutton {
		.p-highlight {
			.p-button-icon {
				color: black !important;
			}

			.p-button-icon:hover {
				color: black !important;
			}

			.p-button-label {
				color: black !important;
			}

			.p-button-label:hover {
				color: black !important;
			}
		}
	}

	p-autocomplete {
		.p-autocomplete {
			.p-button {
				color: black !important;
			}
		}
	}

	.fc-button-active {
		color: black !important;
	}

	.fc-button-active:hover {
		color: black !important;
	}

	.p-dialog-footer button {
		color: black !important;
	}

	.p-splitbutton {
		.p-splitbutton-defaultbutton {
			color: black !important;
		}
		.p-splitbutton-menubutton {
			color: black !important;
		}
	}

	p-dropdown {
		.p-button {
			color: black !important;
		}
		.p-dropdown-trigger {
			color: black !important;
		}
	}

	.p-fieldset {
		.p-button {
			color: black !important;
		}
	}
}
.main-recovery {
	p-button {
		.p-button {
			.p-button-icon {
				color: black !important;
			}

			.p-button-icon:hover {
				color: black !important;
			}

			.p-button-label {
				color: black !important;
			}

			.p-button-label:hover {
				color: black !important;
			}
		}
	}

	p-selectbutton {
		.p-highlight {
			.p-button-icon {
				color: black !important;
			}

			.p-button-icon:hover {
				color: black !important;
			}

			.p-button-label {
				color: black !important;
			}

			.p-button-label:hover {
				color: black !important;
			}
		}
	}

	p-autocomplete {
		.p-autocomplete {
			.p-button {
				color: black !important;
			}
		}
	}

	.fc-button-active {
		color: black !important;
	}

	.fc-button-active:hover {
		color: black !important;
	}

	.p-dialog-footer button {
		color: black !important;
	}

	.p-splitbutton {
		.p-splitbutton-defaultbutton {
			color: black !important;
		}
		.p-splitbutton-menubutton {
			color: black !important;
		}
	}

	p-dropdown {
		.p-button {
			color: black !important;
		}
		.p-dropdown-trigger {
			color: black !important;
		}
	}

	.p-fieldset {
		.p-button {
			color: black !important;
		}
	}
}
.main-credit {
	p-button {
		.p-button {
			.p-button-icon {
				color: black !important;
			}

			.p-button-icon:hover {
				color: black !important;
			}

			.p-button-label {
				color: black !important;
			}

			.p-button-label:hover {
				color: black !important;
			}
		}
	}

	p-selectbutton {
		.p-highlight {
			.p-button-icon {
				color: black !important;
			}

			.p-button-icon:hover {
				color: black !important;
			}

			.p-button-label {
				color: black !important;
			}

			.p-button-label:hover {
				color: black !important;
			}
		}
	}

	p-autocomplete {
		.p-autocomplete {
			.p-button {
				color: black !important;
			}
		}
	}

	.fc-button-active {
		color: black !important;
	}

	.fc-button-active:hover {
		color: black !important;
	}

	.p-dialog-footer button {
		color: black !important;
	}

	.p-splitbutton {
		.p-splitbutton-defaultbutton {
			color: black !important;
		}
		.p-splitbutton-menubutton {
			color: black !important;
		}
	}

	p-dropdown {
		.p-button {
			color: black !important;
		}
		.p-dropdown-trigger {
			color: black !important;
		}
	}

	.p-fieldset {
		.p-button {
			color: black !important;
		}
	}
}

.main-property {
	p-button {
		.p-button {
			.p-button-icon {
				color: black !important;
			}

			.p-button-icon:hover {
				color: black !important;
			}

			.p-button-label {
				color: black !important;
			}

			.p-button-label:hover {
				color: black !important;
			}
		}
	}

	p-selectbutton {
		.p-highlight {
			.p-button-icon {
				color: black !important;
			}

			.p-button-icon:hover {
				color: black !important;
			}

			.p-button-label {
				color: black !important;
			}

			.p-button-label:hover {
				color: black !important;
			}
		}
	}

	p-autocomplete {
		.p-autocomplete {
			.p-button {
				color: black !important;
			}
		}
	}

	.fc-button-active {
		color: black !important;
	}

	.fc-button-active:hover {
		color: black !important;
	}

	.p-dialog-footer button {
		color: black !important;
	}

	.p-splitbutton {
		.p-splitbutton-defaultbutton {
			color: black !important;
		}
		.p-splitbutton-menubutton {
			color: black !important;
		}
	}

	p-dropdown {
		.p-button {
			color: black !important;
		}
		.p-dropdown-trigger {
			color: black !important;
		}
	}

	.p-fieldset {
		.p-button {
			color: black !important;
		}
	}
}

.main-calendar {
	p-button {
		.p-button {
			.p-button-icon {
				color: black !important;
			}

			.p-button-icon:hover {
				color: black !important;
			}

			.p-button-label {
				color: black !important;
			}

			.p-button-label:hover {
				color: black !important;
			}
		}
	}

	p-selectbutton {
		.p-highlight {
			.p-button-icon {
				color: black !important;
			}

			.p-button-icon:hover {
				color: black !important;
			}

			.p-button-label {
				color: black !important;
			}

			.p-button-label:hover {
				color: black !important;
			}
		}
	}

	p-autocomplete {
		.p-autocomplete {
			.p-button {
				color: black !important;
			}
		}
	}

	.fc-button-active {
		color: black !important;
	}

	.fc-button-active:hover {
		color: black !important;
	}

	.fc-resource-timeline {
		.fc-datagrid-cell-cushion {
			text-overflow: ellipsis;
		}
	}

	.p-dialog-footer button {
		color: black !important;
	}

	.p-splitbutton {
		.p-splitbutton-defaultbutton {
			color: black !important;
		}
		.p-splitbutton-menubutton {
			color: black !important;
		}
	}

	p-dropdown {
		.p-button {
			color: black !important;
		}
		.p-dropdown-trigger {
			color: black !important;
		}
	}

	.p-fieldset {
		.p-button {
			color: black !important;
		}
	}
}

.p-column-filter-buttonbar {
	column-gap: 0.5rem;

	.p-button-outlined {
		background-color: var(--primary-color);
		color: #ffffff;
		font-size: 0.875rem;
		box-shadow: none;

		&:enabled {
			&:hover {
				background-color: rgb(97, 124, 138, 0.92);
				color: White;
			}

			&:active {
				background-color: rgb(97, 124, 138, 0.92);
				color: White;
			}
		}

		&:focus:not(:focus-visible) {
			background-color: rgb(97, 124, 138, 0.92);
			color: White;
			box-shadow: none;
		}
	}
}

.p-chips .p-chips-multiple-container {
	flex-wrap: wrap;
	align-content: space-between;
	padding-bottom: 0;

	.p-chips-token {
		margin: 5px;
		color: rgba(255, 255, 255, 0.87);

		.p-chips-token-icon {
			color: #607d8b;
		}
	}
}
.main-schedule {
	p-button {
		.p-button {
			.p-button-icon {
				color: black !important;
			}

			.p-button-icon:hover {
				color: black !important;
			}

			.p-button-label {
				color: black !important;
			}

			.p-button-label:hover {
				color: black !important;
			}
		}
	}

	p-selectbutton {
		.p-highlight {
			.p-button-icon {
				color: black !important;
			}

			.p-button-icon:hover {
				color: black !important;
			}

			.p-button-label {
				color: black !important;
			}

			.p-button-label:hover {
				color: black !important;
			}
		}
	}

	p-autocomplete {
		.p-autocomplete {
			.p-button {
				color: black !important;
			}
		}
	}

	.fc-button-active {
		color: black !important;
	}

	.fc-button-active:hover {
		color: black !important;
	}

	.p-dialog-footer button {
		color: black !important;
	}

	.p-splitbutton {
		.p-splitbutton-defaultbutton {
			color: black !important;
		}
		.p-splitbutton-menubutton {
			color: black !important;
		}
	}

	p-dropdown {
		.p-button {
			color: black !important;
		}
		.p-dropdown-trigger {
			color: black !important;
		}
	}

	.p-fieldset {
		.p-button {
			color: black !important;
		}
	}
}
