/* Sidebar */
$sidebarLogoBgColor: var(--surface-card);
$sidebarBgColor: #566e87;
$darkSidebarBgColor: var(--surface-ground);
$menuBgImageLight: null;
$menuBgImageDark: null;
$horizontalMenuBgImageLight: 'bg-moody-light-horizontal.png';
$horizontalMenuBgImageDark: 'bg-moody-dark-horizontal.png';
$horizontalMenuBgColor: #080a10;
$horizontalDarkMenuBgColor: #0a1118;

/* Primary */
$primaryColor: #607d8b;
$primaryDarkColor: #496475;
$primaryLightColor: #90a4ae;
$primaryLightestColor: #e8eaf6;
$primaryTextColor: #ffffff;

/* Accent */
$accentColor: #51abb1;
$accentLightColor: #76bcc1;
$accentTextColor: #ffffff;

/* Topbar */
$topbarBgColor: var(--surface-ground);
$topbarTextColor: $primaryLightestColor;
$topbarIconColor: $primaryLightestColor;

/* Submenu */
$horizontalSubmenuBgColor: #2b3d4c;
$horizontalDarkSubmenuBgColor: #0e161e;
$horizontalSubmenuitemHoverBgColor: rgba(255, 255, 255, 0.4);
$horizontalSubmenuitemDarkHoverBgColor: rgba(255, 255, 255, 0.4);
$horizontalSubmenuitemDarkHoverTextColor: #ffffff;
$horizontalMenuActiveTextColor: #ffffff;
$horizontalMenuActiveHoverTextColor: #ffffff;
$horizontalDarkMenuActiveTextColor: #ffffff;
$horizontalDarkMenuActiveHoverTextColor: #ffffff;

/* Default MenuItem */
$menuitemTextColor: #ffffff;
$menuitemIconTextColor: #ffffff;

/* Hover MenuItem */
$menuitemHoverBgColor: rgba(0, 0, 0, 0.4);
$menuitemHoverTextColor: #ffffff;
$menuitemHoverIconTextColor: #ffffff;

/* Active MenuItem */
$menuitemActiveBgColor: $accentColor;
$menuitemActiveTextColor: #ffffff;
$menuitemActiveIconTextColor: #ffffff;
$subMenuitemActiveTextColor: $accentLightColor;
$subMenuitemActiveIconTextColor: $accentLightColor;

/* Dark Default MenuItem */
$darkMenuitemTextColor: #dee0e3;
$darkMenuitemIconTextColor: #dee0e3;

/* Dark Hover MenuItem */
$darkMenuitemHoverBgColor: rgba(255, 255, 255, 0.4);
$darkMenuitemHoverTextColor: #ffffff;
$darkMenuitemHoverIconTextColor: #ffffff;

/* Dark Active MenuItem */
$darkMenuitemActiveBgColor: $accentColor;
$darkMenuitemActiveTextColor: #ffffff;
$darkMenuitemActiveIconTextColor: #ffffff;
$darksubMenuitemActiveTextColor: $accentLightColor;
$darksubMenuitemActiveIconTextColor: $accentLightColor;

@import '../sass/layout/_layout';
