/* You can add global styles to this file, and also import other style files */

@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeflex/primeflex.scss';
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/prismjs/themes/prism-coy.css';

@import './assets/styles/app-theme.scss';
@import './assets/styles/app-layout.scss';
@import 'assets/scss';
