.table-header-label {
	display: flex;
	align-items: center;
	width: fit-content;
	min-height: 40px;
}

p-columnfilter {
	display: inline-block;

	.p-column-filter {
		display: inline-block;

		&-menu-button {
			filtericon {
				display: inline-flex;
				line-height: 9px;
			}
		}
	}
}
