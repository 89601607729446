@import '../../scss/mixins/colors';
@import '../../scss/variables/color';

/* Add your variable customizations of theme here */

// Card
$cardShadow: unset;

// Datatable
//$tableHeaderTextColor:$textColor;
$tableHeaderFontWeight: 700;
$tableHeaderCellBg: rgba(var(--primary-color), 0.25);
$tableHeaderCellHoverBg: rgba(var(--primary-color), 0.1);
$tableHeaderCellHighlightHoverBg: rgba(var(--primary-color), 0.1);
$tableBodyRowBg: rgba(var(--primary-color), 0.1);
$tableBodyRowEvenBg: $tableBodyRowBg; // No alternate row color
$tableBodyRowHoverBg: rgba(var(--primary-color), 0.25);
$tableBodyCellBorder: 1px solid var(--primary-color);

//tabview
//$textSecondaryColor:$emphasis-high;
$tabviewHeaderFontWeight: 700;
$tabviewHeaderTextColor: $textColor;
$tabviewHeaderHoverBg: rgba(var(--primary-color), 0.25);
$tabviewHeaderTextHoverColor: $textColor;
$tabviewHeaderTextActiveColor: $textColor;

//toggleButton
$datatableSelectButtonBg: rgba(var(--primary-color), 0.25);

// Edited selection
$primary-colors: (
	default: $default-primary-color,
	scope: $scope-primary-color,
	quote: $quote-primary-color,
	workorder: $workorder-primary-color,
	asset: $asset-primary-color,
	property: $property-primary-color,
	invoice: $invoice-primary-color,
	sor: $sor-primary-color,
	purchaseorder: $purchaseorder-primary-color,
	service-request: $service-request-primary-color,
	contact: $contact-primary-color,
	payables: $payables-primary-color,
	receivables: $receivables-primary-color,
	calendar: $calendar-primary-color,
	schedule: $schedule-primary-color,
	recovery: $recovery-primary-color,
	credit: $credit-primary-color,
	defect: $defect-primary-color,
);

@each $key, $value in $primary-colors {
	#{if($key == "default", ":root", ".main-#{$key}")} {
		@include app-primary-color($value);
	}
}
