/* Utils */
.clearfix:after {
	content: ' ';
	display: block;
	clear: both;
}

*[hidden] {
	display: none;
}

.card {
	@include content-shadow();
	@include border-radius(2px);
	background: var(--surface-card);
	padding: 16px 0;
	margin-bottom: 16px;
	box-sizing: border-box;

	&.card-w-title {
		padding-bottom: 32px;
	}

	.card-header {
		@include flex();
		@include flex-align-center();
		@include flex-justify-between();
	}

	.card-subtitle {
		color: var(--text-color-secondary);
		font-weight: 600;
		margin: -1rem 0 1rem 0;
	}
}

p-toast {
    .p-toast.p-toast-clear {
        .p-button {
            .p-button-label{
                color: #607d8b !important;
            }
        }
    }
}


.p-toast {
	&.p-toast-top-right,
	&.p-toast-top-left,
	&.p-toast-top-center {
		top: 70px;
	}
}

@-webkit-keyframes fadeInDown {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

@keyframes fadeInDown {
	from {
		opacity: 0;
		transform: translate3d(0, -20px, 0);
	}

	to {
		opacity: 1;
		transform: none;
	}
}

@-webkit-keyframes fadeOutUp {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0);
	}
}

@keyframes fadeOutUp {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0);
	}
}

@-webkit-keyframes spin {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(359deg);
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

.fadeInDown {
	-webkit-animation: fadeInDown 5s; /* Safari 4.0 - 8.0 */
	animation: fadeInDown 5s;
}

.fadeOutUp {
	-webkit-animation: fadeOutUp $transitionDuration; /* Safari 4.0 - 8.0 */
	animation: fadeOutUp $transitionDuration;
}
